import { Col, Row } from "antd";
import React from "react";
import { FooterContainer, Line } from "./index.style";
import { Link } from "react-router-dom";

const MainFooter = () => {
  return (
    <FooterContainer>
      <Row align="middle">
        <Col xs={{ span: 24 }} lg={{ span: 6 }}>
          <div className="logo">
            <img src="/icons/new-logo.svg" alt="" />
          </div>
        </Col>
        <Col xs={{ span: 24 }} lg={{ span: 6 }}>
          <h4>Connect with us</h4>
          <div className="socialLinks">
            <a
              href="https://www.facebook.com/nuziveeduseedslimited/"
              target="_blank"
            >
              <img src="/images/f.png" alt="" />
            </a>
            <a
              href="https://www.instagram.com/nuziveedu_seeds_limited/?hl=en"
              target="_blank"
            >
              <img src="/images/ista.png" alt="" />
            </a>
            <a
              href="https://www.youtube.com/@nuziveeduseedslimited"
              target="_blank"
            >
              <img src="/images/youtube.png" alt="" />
            </a>
            <a
              href="https://www.linkedin.com/company/nuziveedu-seeds-limited/"
              target="_blank"
            >
              <img src="/images/lin.png" alt="" />
            </a>
            {/* <a href="">
              <img src="/icons/t.svg" alt="" />
            </a> */}
          </div>
        </Col>
        <Col xs={{ span: 12 }} lg={{ span: 6 }}>
          <div className="homeLinks">
            <Link to="/seeding">Seeding the Future</Link>
            {/* <Link to="/celebrate">Participate and Win</Link> */}
            <Link to="/gallery">Gallery</Link>
          </div>
        </Col>
        <Col xs={{ span: 12 }} lg={{ span: 6 }}>
          <div className="homeLinks">
            <Link to="/contact">Contact Us</Link>
          </div>
        </Col>
      </Row>
      <Line></Line>
      <p>
        <strong style={{ fontWeight: "800" }}>TM</strong> &reg; Trademarks of
        Nuziveedu Seeds Limited and its affiliated companies. &copy; 2024
        Nuziveedu Seeds Limited
      </p>
    </FooterContainer>
  );
};

export default MainFooter;

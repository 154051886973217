import React, { useState } from "react";
import {
  HeaderContainer,
  LinkItem,
  MobileNav,
  Nav,
  ToggleBtn,
} from "./index.style";
import { Link } from "react-router-dom";
import { FaBars } from "react-icons/fa";

const Header = () => {
  const [mobileLinks, setMobileLinks] = useState(false);
  return (
    <HeaderContainer>
      <Link className="homeLogo" to="/" onClick={() => setMobileLinks(false)}>
        <img src="/images/new-logo.svg" alt="" />
      </Link>
      <Nav>
        <LinkItem to="/seeding">Seeding the Future</LinkItem>
        {/* <LinkItem to="/celebrate">Participate and Win</LinkItem> */}
        {/* <LinkItem to="/">Initatives</LinkItem> */}
        <LinkItem to="/gallery">Gallery</LinkItem>
        {/* <LinkItem to="/news">News</LinkItem> */}
        <LinkItem to="/contact">Contact Us</LinkItem>
      </Nav>
      <ToggleBtn onClick={() => setMobileLinks(!mobileLinks)}>
        <svg
          width="15"
          height="14"
          viewBox="0 0 15 14"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M1 1H14"
            stroke="black"
            stroke-width="2"
            stroke-linecap="round"
          />
          <path
            d="M1 7H14"
            stroke="black"
            stroke-width="2"
            stroke-linecap="round"
          />
          <path
            d="M1 13H14"
            stroke="black"
            stroke-width="2"
            stroke-linecap="round"
          />
        </svg>
      </ToggleBtn>
      {mobileLinks ? (
        <MobileNav style={mobileLinks ? { height: "" } : { height: "0" }}>
          <LinkItem to="/seeding" onClick={() => setMobileLinks(false)}>
            Seeding the Future
          </LinkItem>
          <LinkItem to="#" onClick={() => setMobileLinks(false)}>
            Participate and Win
          </LinkItem>
          {/* <LinkItem to="/">Initatives</LinkItem> */}
          <LinkItem to="/gallery" onClick={() => setMobileLinks(false)}>
            Gallery
          </LinkItem>
          {/* <LinkItem to="/news">News</LinkItem> */}
          <LinkItem to="/contact" onClick={() => setMobileLinks(false)}>
            Contact Us
          </LinkItem>
        </MobileNav>
      ) : (
        ""
      )}
    </HeaderContainer>
  );
};

export default Header;
